








































import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'
import { postController } from '@/modules/common/dialogs/post/post-controller'

@Observer
@Component({
  components: {},
})
export default class DeletePostDialog extends Vue {
  postController = postController
}
